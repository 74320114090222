import React from "react"

import styled from "styled-components"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import ContentWrapper from "../components/ContentWrapper/ContentWrapper"
import Banner from "../components/Banner/Banner"
import H1 from "../components/H1/H1"
import H2 from "../components/H2/H2"

import imageBanner from "../assets/images/banner_bg_3.png"

const StyledContactContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  top: 0;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  margin: 0 auto 50px auto;

  ${({ theme }) => theme.media.tablet} {
    margin-bottom: 100px;
  }
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 150px;
    flex-direction: row;
  }
  div {
    ${({ theme }) => theme.media.desktop} {
      margin-right: 50px;
    }

    h1 {
      color: ${({ theme }) => theme.colors.secondary};
      font-size: 2rem;
      line-height: 2rem;

      ${({ theme }) => theme.media.tablet} {
        font-size: 3rem;
        line-height: 3rem;
      }
    }
    h2 {
      padding-left: 50px;
      margin-bottom: 30px;

      font-size: 1.4rem;
      line-height: 1.4rem;

      ${({ theme }) => theme.media.tablet} {
        font-size: 1.8rem;
        line-height: 1.8rem;
      }

      ::before {
        content: "";
        height: 3px;
        width: 40px;
        background: ${({ theme }) => theme.colors.primary};
        display: inline-block;
        position: absolute;
        left: 0;
        top: calc(50% - 2px);
      }
    }

    div {
      div {
        display: flex;
        margin-left: 50px;

        svg {
          filter: invert(46%) sepia(8%) saturate(2706%) hue-rotate(314deg)
            brightness(111%) contrast(90%);
        }

        p {
          margin: 0 0 24px 8px;
        }
      }
      div:last-child {
        h4 {
          margin: 0 0 0 8px;
        }
      }
    }
  }
`

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <div style={{ height: "60px" }} />
      <Banner title="KONTAKT" image={imageBanner} />

      <ContentWrapper>
        <StyledContactContent>
          <div>
            <H1>Pozostańmy w kontakcie</H1>
            <H2 gold>HEAD OFFICE</H2>
            <div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
                </svg>
                <p>+48 538 436 332</p>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z" />
                </svg>
                <p>Poniedziałek - Sobota: 10:00 do 22:00</p>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
                </svg>
                <p>
                  ulica Aleksandra Kamińskiego 10/32 <br />
                  03-130 Warszawa, Polska
                </p>
              </div>
              <div>
                <p>Numer KRS 0001001980</p>
              </div>
              <div>
                <p>NIP 5242955219</p>
              </div>
              <div>
                <p>REGON 523651712</p>
              </div>
              {/* <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
                </svg>
                <p>lukasz.gawronski@medinvest.com.pl</p>
              </div> */}
            </div>
          </div>
        </StyledContactContent>
      </ContentWrapper>
    </Layout>
  )
}

export default ContactPage
